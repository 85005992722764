<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SelectWrapper',
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input{
  @apply w-auto;
}
::v-deep .el-select{
  @apply w-auto;
}
</style>
