<template>
  <BaseDialog
    :show="true"
    width="800px"
    title="選擇會員"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div class="reward-postpone-member-selector">
      <div style="padding-bottom: 20px">
        <MemberSearch :model.sync="search.member" />
      </div>
      <el-table
        ref="tableRef"
        v-loading="loading"
        :data="displayData[tableOptions.page - 1]"
        :row-key="getRowKeys"
        @selection-change="handleSelectionChange"
      >
        <template #empty><EmptyBlock /></template>

        <el-table-column
          type="selection"
          width="50"
          align="center"
          :reserve-selection="true"
        />

        <el-table-column label="會員電話" prop="phone">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="會員電話" prop="phone" width="120px" />
        <el-table-column label="會員姓名" prop="name" />
        <el-table-column label="獎勵類型" prop="rewardType" />
        <el-table-column label="前年度有效獎勵" prop="balance" />
        <el-table-column label="前年度獎勵到期日" prop="walletExpDate" width="120px" />
        <el-table-column label="已展延" prop="walletExpDate" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.postponed.tagType">{{ scope.row.postponed.label }}</Tag>
        </template>
      </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="search.member ? 1 : availableMembers.length"
        @pageChange="onPageChange"
      />
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, reactive, computed, ref, watch, onMounted } from 'vue'
import { map, filter, get, chunk, isEmpty, find } from 'lodash'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import { useTable } from '@/use/table'
import { GetMembersBatchPointYearlyWallet, GetMembersBatchCashbackYearlyWallet } from '@/api/memberBatchTask'
import { rewardPostponeTaskTypesConfig } from '@/config/rewardPostpone'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'RewardPostponeMemberSelector',
  components: { BaseDialog, EmptyBlock, MemberSearch },
  props: {
    members: { type: Array, default: () => [] },
    rewardType: String,
  },
  setup (props, { emit }) {
    const search = reactive({
      member: null,
    })
    const selected = ref([])
    const tableRef = ref([])
    const loading = ref(false)

    const { tableOptions, pageStartIndex, dateFormat } = useTable()
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const membersYearlyWallet = ref([])

    const availableMembers = computed(() => {
      return map(filter(props.members, m => !m.isRemove), (member) => {
        const wallet = find(membersYearlyWallet.value, { memberId: get(member, 'id') })
        // pointWalletYearExpirations 因為同樣用點數的微服務，所以命名相同
        const yearlyWallet = find(get(wallet, 'pointWalletYearExpirations'), { year: dayjs().year() - 1 })
        return {
          id: get(member, 'id'),
          phone: get(member, 'UserInfo.phone'),
          name: get(member, 'UserInfo.name'),
          rewardType: get(rewardPostponeTaskTypesConfig, `${props.rewardType}.label`),
          balance: yearlyWallet ? get(yearlyWallet, 'balance') : '-',
          walletExpDate: yearlyWallet ? dateFormat(get(yearlyWallet, 'expirationDate')) : '-',
          postponed: get(yearlyWallet, 'expiredAt') ? { label: '是', tagType: 'action' } : { label: '否', tagType: 'info' },
        }
      })
    })

    const displayData = computed(() => {
      let listData = availableMembers.value
      if (search.member) listData = filter(availableMembers.value, (i) => i.id === get(search, 'member.id'))
      return chunk(listData, tableOptions.pageLimit)
    })

    const refreshWalletData = async (data) => {
      loading.value = true
      let apiMethod
      if (props.rewardType === rewardPostponeTaskTypesConfig.pointYearlyExtension.value) {
        apiMethod = GetMembersBatchPointYearlyWallet
      } else if (props.rewardType === rewardPostponeTaskTypesConfig.cashbackYearlyExtension.value) {
        apiMethod = GetMembersBatchCashbackYearlyWallet
      }

      await simpleFetch(apiMethod, {
        shopId: shopId.value,
        year: dayjs().year() - 1,
        memberIds: map(data, 'id'),
      }, (res) => {
        membersYearlyWallet.value = res
      })
      loading.value = false
    }

    watch(() => props.rewardType, async () => {
      const data = availableMembers.value
      if (!data || isEmpty(data)) return
      refreshWalletData(data)
    })

    const getRowKeys = (row) => row.id
    const handleSelectionChange = (val, row) => {
      console.log('val', val)
      selected.value = val
    }

    const onPageChange = () => {
      refreshWalletData(displayData.value[tableOptions.page - 1])
    }

    const onConfirm = () => {
      emit('confirm', selected.value)
      emit('close')
    }

    onMounted(async () => {
      refreshWalletData(displayData.value[0])
    })

    return {
      selected,
      search,
      displayData,
      onConfirm,
      pageStartIndex,
      tableOptions,
      handleSelectionChange,
      availableMembers,
      tableRef,
      onPageChange,
      getRowKeys,
      loading,
      membersYearlyWallet,
    }
  },
})
</script>

<style lang="postcss" scoped>
.reward-postpone-member-selector {
}
</style>
