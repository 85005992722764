<template>
  <div class="reward-postpone-trigger-block side-highlight-container">
    <p class="card-title">異動時間</p>

    <el-form-item props="trigger.planExecutionAt">
      <el-radio-group>
        <el-radio>立即異動</el-radio>
      </el-radio-group>
    </el-form-item>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { rewardPostponeTaskStatusConfig } from '@/config/rewardPostpone'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'RewardPostponeInfoBlock',
  props: {
    form: { type: Object, defalt: () => ({}) },
  },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'form', emit)

    return { rewardPostponeTaskStatusConfig, syncForm }
  },
})
</script>

  <style lang="postcss" scoped>

  </style>
