<template>
  <div class="reward-postpone-info-block side-highlight-container">
    <p class="card-title">展延設定</p>
    <el-alert
      v-show="noTaskTypeOptions"
      style="margin-bottom: 20px;"
      title="提醒："
      type="warning"
      show-icon
      center
      :closable="false"
    >
      <p>目前未開啟相關獎勵回饋模組，因此無法進行設定。 如有相關需求請與歐巴小幫手聯繫，以取得協助。</p>
    </el-alert>
    <el-form-item label="異動類型" prop="info.type" label-position="left" label-width="127px">
      <SelectWrapper>
        <el-select v-model="syncForm.type" style="width: 220px" clearable :disabled="noTaskTypeOptions">
          <el-option
            v-for="status in taskTypeOptions"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </SelectWrapper>
    </el-form-item>
    <el-form-item label="展延後有效日期" prop="info.expAt">
      <el-date-picker
        v-model="syncForm.expAt"
        type="date"
        :picker-options="pickerOptions"
      />
    </el-form-item>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import { rewardPostponeTaskTypesConfig } from '@/config/rewardPostpone'
import { useVModel } from '@vueuse/core'
import dayjs from 'dayjs'
import store from '@/store'
import { omit, get, isEmpty } from 'lodash'
import SelectWrapper from '@/components/SelectWrapper.vue'

export default defineComponent({
  name: 'RewardPostponeInfoBlock',
  components: { SelectWrapper },
  props: {
    form: { type: Object, defalt: () => ({}) },
  },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'form', emit)
    const marketingPermission = computed(() => store.getters.computedShopMarketingPermission)
    const noTaskTypeOptions = computed(() => isEmpty(taskTypeOptions.value))

    const taskTypeOptions = computed(() => {
      const omitList = []
      if (!get(marketingPermission.value, 'point')) omitList.push('pointYearlyExtension')
      if (!get(marketingPermission.value, 'cashback')) omitList.push('cashbackYearlyExtension')

      return omit(rewardPostponeTaskTypesConfig, omitList)
    })

    const pickerOptions = {
      disabledDate: (date) => {
        const nextYear = dayjs().add(1, 'year')
        const minDate = dayjs().set('month', 1).set('date', 1).startOf('date')
        const maxDate = nextYear.set('month', 0).set('date', 31).endOf('date')
        return dayjs(date).isBefore(minDate) || dayjs(date).isAfter(maxDate)
      },
    }

    onMounted(() => {
      const newYear = dayjs().set('month', 1).set('date', 1).startOf('date')

      // 自動預設:
      syncForm.value.expAt = newYear
      setTimeout(() => {
        if (!noTaskTypeOptions.value) syncForm.value.type = rewardPostponeTaskTypesConfig.pointYearlyExtension.value
      }, 100)
    })

    return { rewardPostponeTaskTypesConfig, syncForm, taskTypeOptions, marketingPermission, noTaskTypeOptions, pickerOptions }
  },
})
</script>

<style lang="postcss" scoped>

</style>
